import React from "react"
import Layout from '../components/layout';
import Banner from '../components/banner';
import Contactform from '../components/contactForm';
import { Helmet } from "react-helmet";
// IMAGES
import umbrellapeople from '../images/umbrellapeople.jpg';

const Page = () => (
  <Layout>
    <Helmet>
      <title>Contact Us | Products By Design</title>
    </Helmet>
    <Banner
      title="We provide quality products personalised to your brand."
      description="Having trouble finding the right promotional products for your business? Let us find them for you, leaving you more time to focus on what you do best – meeting your clients’ needs."
      button={{
        title: "View Products",
        url: "/"
      }}
      image={umbrellapeople}
      alignment="50% 10%"
    />

    <Contactform />
  </Layout>
)

export default Page
