import React, {useEffect} from "react";

const ContactForm = (props) => {

  useEffect(() => {
   
    // Client JS for Ajax form submit    
    let form = document.querySelector("#form-section form");
    let formSuccessMsg = document.querySelector(".form-success")
    form.addEventListener('submit', function(e){
    
        e.preventDefault();
        const formData = new FormData(form);
        var stringData = new URLSearchParams(formData).toString();


        // body: {
        //   "form-name": form.getAttribute("name"),
        //   "name": encodeURI(stringData.get("name")),
        //   "company": encodeURI(stringData.get("company")),
        //   "email": encodeURI(stringData.get("email")),
        //   "tel": encodeURI(stringData.get("tel")),
        //   "message": encodeURI(stringData.get("message"))
        // }

        fetch("?" + stringData, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then(function(){
            form.classList.add("hide");
            formSuccessMsg.classList.add("true")
        });

    });


  });
  
  return (
    <div className="form__block" id="form-section">
      <div className="container" >
        <section id="form-section">
          <form name="contact" data-netlify="true">
            <h2 id="contact-form" className="footer-heading">Thanks for reaching out</h2>
            <input type="hidden" name="form-name" value="contact" />
            <label>Your Name: <input type="text" name="name" required/></label>
            <label>Company Name: <input type="text" name="company" /></label>   
            <label>Contact Email: <input type="email" name="email" /></label>
            <label>Contact Number: <input type="tel" name="phone" /></label>
            <label>Message: <textarea name="message" required></textarea></label>
            <button className="btn" type="submit">Send</button>
          </form>
          <div className="form-success"><i className="gg-check-o"></i><p>Message Sent!</p></div>
        </section>
      </div>
    </div>
  );
};


export default ContactForm;
